import React, { useState, useEffect } from "react";
import { IoMdCheckmark } from "react-icons/io";
import WelcomeScreen from "./welcomeScreen";
import Identification from "./identification";
import Contact from "./contacts";
import Button from "../components/Button";
import Purpose from "./purpose";
import Submit from "./submit";
import NavIndicator from "../components/NavIndicator";
import Layout from "../components/Layout/layout";
import validation from "../services/Validation";
import { useNavigate } from "react-router-dom";
import Toast from '../services/Toast'
import axios from "axios";
const errorFields = {
  firstNameError: "",
  middleNameError: "",
  surnameError: "",
  nationalityError: "",
  idCardTypeError: "",
  idCardDetialsError: "",
  phoneNumberError: "",
  emailError: "",
  puposeOfVisitError: "",
  termsCheckError: "",
  dobError:"",
  othersSpecifyError: "",
};

const BASE_URL = "https://cgiapi.affordmate.com/index.php";
const MultistepForm = () => {
  const navigate = useNavigate();

  const [currentStep, setCurrentStep] = useState(1);
  const [data, setData] = useState();
  const [isEnableButton, setIsEnableButton] = useState(true);
  const [isLoading , setIsLoading] = useState(false);

  const [formData, setFormData] = useState({
    first_name: "",
    middle_name: "",
    surname: "",
    dob: "",
    country_id: "",
    id_type: "",
    id_number: "",
    mobile: "",
    email: "",
    purpose_of_visit: "",
    others_specify:"",
    photo: "",
    termsChecked: false,
  });
  useEffect(()=>{
    fetchData();
  },[])
  const fetchData = async () => {
    try {
      const response = await axios.get(BASE_URL+"/data");
      setData(response.data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }
  const isValdateForm = () => {
    setFormData((prev) => ({ ...prev, ...errorFields }));
    // Form 1 Validation
    if (currentStep === 1) {
      let validateFirstName = validation.validateText(
        formData.first_name,
        "First Name",
        true
      );
      let validateMiddleName = validation.validateText(
        formData.middle_name,
        "Middle Name"
      );
      let validateSurname = validation.validateText(
        formData.surname,
        "SurName"
      );
      let validateDob = validation.validateDate(
        formData.dob,
        "Date of Birth",
        true
    );

      if (
        !validateFirstName.isValid ||
        !validateMiddleName.isValid ||
        !validateSurname.isValid ||
        !validateDob.isValid
      ) {
        setFormData((prev) => ({
          ...prev,
          firstNameError: validateFirstName.errorMessage,
          middleNameError: validateMiddleName.errorMessage,
          surnameError: validateSurname.errorMessage,
          dobError: validateDob.errorMessage
        }));
        return false;
      }
    }

    // Form 2 Validation
    if (currentStep == 2) {
      let validateNationality = validation.notEmpty(
        formData.country_id,
        "Nationality"
      );
      let validateIdType = validation.notEmpty(formData.id_type, "ID Type");
      let validateIdNumber = validation.validateIdNumber(
        formData.id_type,
        formData.id_number,
      );

      if (
        !validateIdType.isValid ||
        !validateIdNumber.isValid
      ) {
        console.log("Is Not Valid");
        setFormData((prev) => ({
          ...prev,
          nationalityError: validateNationality.errorMessage,
          idCardTypeError: validateIdType.errorMessage,
          idCardDetialsError: validateIdNumber.errorMessage,
        }));

        return false;
      }
    }

    // Form 3 Validation
    if (currentStep == 3) {
      let validatePhoneNumber = validation.phoneValidation(
        formData.phoneNumber,
        "Phone Number"
      );
      let validateEmail = validation.emailValidation(formData.email);
      if (!validatePhoneNumber.isValid || !validateEmail.isValid) {
        setFormData((prev) => ({
          ...prev,
          phoneNumberError: validatePhoneNumber.errorMessage,
          emailError: validateEmail.errorMessage,
        }));
        return false;
      }
    }

    // form validation 3
    if (currentStep == 4) {
      let validatePurpose = validation.notEmpty(
        formData.purpose_of_visit,
        "Purpose of Visit"
      );
      if (!validatePurpose.isValid ) {
        setFormData((prev) => ({
          ...prev,
          puposeOfVisitError: validatePurpose.errorMessage,
        }));
      }
      let validateDateOthers = validation.notEmpty(
        formData.others_specify,
        "Others"
      )
      if (!validateDateOthers.isValid ) {
        setFormData((prev) => ({
          ...prev,
          othersSpecifyError: validateDateOthers.errorMessage,
        }));
      }
      
      if (!formData?.photo) {
        setFormData((prev) => ({
          ...prev,
          photoError: "Photo cannot be blank.",
        }));
        return false;
      }

      if (!formData?.termsChecked) {
        setFormData((prev) => ({
          ...prev,
          termsCheckError: "Please accept terms and conditions.",
        }));
        return false;
      }
    
    }
    if(currentStep == 4){
      handleSubmit();
      return false
    } else {
      return true;

    }
  };

  const handleNext = () => {
    if (isValdateForm()) setCurrentStep((prev) => prev + 1);
  };

  const handleBack = () => {
    setCurrentStep((prev) => prev - 1);
  };

  const handleSubmit = (e) => {
    // console.log("Form Data:", formData);
    setIsLoading(true);
    let form = new FormData();
    for (let key in formData) {
      form.append(key, formData[key]);
    }
    const postData = async (formData) => {
      try {
        const response = await axios.post(BASE_URL + "/register", formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });

       
        if(response.data.status == 200) {
          navigate(`/submit?r=${response.data.data.reg_no}`);
          setIsLoading(false);

        }else {
          Toast.error(response.message);
        }
        // Add logic for handling the response after posting the data
      } catch (error) {
        console.error("Error posting data:", error);
        // Add logic for error handling
      }
    };
    // setCurrentStep(5);
    // Add form submission logic here
    postData(form);
  };

  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <WelcomeScreen
            formData={formData}
            handleNext={handleNext}
            setFormData={setFormData}
          />
        );
      case 2:
        return <Identification formData={formData} setFormData={setFormData} data={data} />;
      case 3:
        return <Contact formData={formData} setFormData={setFormData} data={data}/>;
      case 4:
        return <Purpose setIsEnableButton={setIsEnableButton} formData={formData} setFormData={setFormData} data={data}/>;
      case 5:
        return <Submit formData={formData} setFormData={setFormData} />;
      default:
        return null;
    }
  };

  return (
    <Layout>
      <div className="wrapper">
        <div className="formContainer">
          {currentStep === 1 && (
            <>
              <h1>Welcome to the Consulate General of India Sydney</h1>
              <p>
                Fill in the registration data. It will take a couple of minutes.{" "}
              </p>
    
    </>
          )}
          <form  className="multistepCutstomForm" onSubmit={(e)=>e.preventDefault()}>
            <NavIndicator currentStep={currentStep} />
            {renderStep()}
            

            {(currentStep != 5 && isEnableButton) && (
              <div className="multistepCustomButton">
                <div style={{ gap: 10, display: "flex" }}>
                  {currentStep > 1 && (
                    <Button
                      buttonText={"Back"}
                      className={"backButton"}
                      onClick={handleBack}
                    />
                  )}
                  {currentStep < 4 ? (
                    <Button
                      buttonText={"Next"}
                      className={"buttonContainer"}
                      onClick={handleNext}
                    />
                  ) : (
                    <Button
                      // icon={<IoMdCheckmark />}
                      buttonText={"Submit"}
                      className={"submitButton"}
                      onClick={handleNext}
                    />
                  )}
                </div>
               
              </div>
            )}
             {currentStep === 4 && isLoading && <p style={{textAlign:'center', paddingTop:'5px'}}>Submitting...</p>}
          </form>
        </div>
      </div>
    </Layout>
  );
};

export default MultistepForm;

import { BrowserRouter, Route, Routes } from "react-router-dom";
import WelcomeScreen from "../screens/welcomeScreen";
import Identification from "../screens/identification";
import Contact from "../screens/contacts";
import Purpose from "../screens/purpose";
import CameraPage from "../screens/camera";
import Submit from "../screens/submit";
import Home from "../screens/index";

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        {/* <Route path="/identification" element={<Identification />} />
        <Route path="/contact" element={<Contact />} /> */}
        <Route path="/purpose" element={<Purpose />} />
        <Route path="/camera" element={<CameraPage />} />
        <Route path="/submit" element={<Submit />} />
      </Routes>
    </BrowserRouter>
  );
};
export default Router;

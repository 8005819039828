import Dropdown from "../components/Dropdown/dropDown";
import Layout from "../components/Layout/layout";
import TextField from "../components/TextField/textfield";
import Button from "../components/Button";
import { useNavigate } from "react-router";
import { useEffect, useState } from "react";
import validation from "../services/Validation";
import NavIndicator from "../components/NavIndicator";
import Select from "react-select";
const Identification = ({ formData, setFormData, data }) => {
  const navigate = useNavigate();
  // console.log("data in identifications", data?.countries, formData);
  const [selectedOption, setSelectedOption] = useState();
  const [idCardLabel, setIdCardLabel] = useState(null);
  // const Country = Object.values(data.countries);

  const countries = data?.countries;

  console.log("countries", countries);
  const idTypes = data?.id_types;

  // ];
  // const CountriesData = countries.map(item => item.label);

  // console.log("countries data", CountriesData);

  // const countryNames = countries;

  // console.log("country namess", countryNames.forEach(name => console.log(name)))
  return (
    // <Layout>
    <div className="wrapper">
      <div className="formContainer">
        {/* <NavIndicator /> */}
        <h2>Identification</h2>

        <Dropdown
          label={"Nationality"}
          Options={countries}
          important={true}
          className={"dropdown"}
          value={formData?.country_id}
          onChange={(e) => {
            setFormData({
              ...formData,
              country_id: e.value,
              nationalityError: "",
            });
          }}
          errorMessage={formData?.nationalityError}
          // data={{ value: options }}
        />
        {/* <Select 
        Options={countries.map((item)=>item?.label)}
        value={formData?.country_id}
        onChange={(e) => {
          setFormData({ ...formData, country_id: e.target.value ,nationalityError:""});
        }}
       
/> */}

        <Dropdown
          label={"ID card details"}
          Options={idTypes}
          important={true}
          className={"dropdown"}
          value={formData?.id_type}
          // onChange={(e) => {
          //   setIdCardLabel(e.target.options[e.target.selectedIndex].text);
          // }}
          onChange={(e) => {
            setIdCardLabel(e.label);
            setFormData({
              ...formData,
              id_type: e.value,
              idCardTypeError: "",
              idCardLabel: e.label,
            });
          }}
          errorMessage={formData?.idCardTypeError}
        />

        {formData?.idCardLabel && (
          <TextField
            label={formData?.idCardLabel}
            placeholder={formData?.idCardLabel}
            important={true}
            type={"text"}
            value={formData?.id_number}
            onChange={(e) => {
              setFormData({
                ...formData,
                id_number: e.target.value,
                idCardDetialsError: "",
              });
            }}
            errorMessage={formData?.idCardDetialsError}
            style={{padding:'5px'}}
          />
        )}

        {/* <div className="buttons">
            <Button
              buttonText={"Back"}
              className={"backButton"}
              onClick={() => navigate("/")}
            />
             <Button
              buttonText={"Next"}
              className={"buttonContainer"}
              onClick={() => nextClick()}
            />
          </div> */}
      </div>
    </div>
    // </Layout>
  );
};
export default Identification;

// import React from 'react';
// import styles from './nav.module.css'

// const NavIndicator = () => {
//   return (
//     <div className={styles.navContainer}>
//      <div className={styles.navBubble}></div>
//      <div className={styles.navLine}></div>
//      <div className={styles.navBubbleActive}></div>
//      <div className={styles.navLine}></div>
//      <div className={styles.navBubble}></div>
//      <div className={styles.navLine}></div>
//      <div className={styles.navBubble}></div>

//     </div>
//   );
// };

// export default NavIndicator;
import React from 'react';
import styles from './nav.module.css';

const NavIndicator = ({ currentStep }) => {
  const steps = 4; // Adjust the number of steps according to your form
  const indicators = [];

  for (let i = 1; i <= steps; i++) {
    indicators.push(
      <React.Fragment key={i}>
        <div className={i <= currentStep ? styles.navBubbleActive : styles.navBubble}></div>
        {i < steps && <div className={styles.navLine}></div>}
      </React.Fragment>
    );
  }

  return <div className={styles.navContainer}>{indicators}</div>;
};

export default NavIndicator;


import { Children } from "react";
import { Emblem } from "../../assests/images";
import styles from "./layout.module.css";

import MultiStep from "react-multistep";
import Identification from "../../screens/identification";
import Contact from "../../screens/contacts";
import Purpose from "../../screens/purpose";
import Submit from "../../screens/submit";
import WelcomeScreen from "../../screens/welcomeScreen";

const Layout = ({ children, prevButton, nextButton , multistep = true}) => {
  return (
    <>
      <div className={styles.header}>
        <div className={styles.imageContainer}>
          <img src={Emblem} alt="emblem" />
        </div>
        <div className={styles.headContent}>
          <h3>Consulate General of India</h3>
          <b className={styles.sydney}>Sydney</b>
        </div>
      </div>
      {children}
      {/* {multistep && <MultiStepNavigation />} */}

    </>
  );
};
export default Layout;

import React, { useRef, useState } from "react";
import { Camera } from "react-camera-pro";
import { useNavigate, useLocation } from "react-router-dom";
import Layout from "../components/Layout/layout";

const CameraComponent = ({onTake}) => {
  const camera = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [error, setError] = useState();
  console.log("error: ", error)
  const [image, setImage] = useState(null);

  const capture = () => {
    const capturedImage = camera.current.takePhoto();
    setImage(capturedImage);
  };

  const savePhoto = () => {
    onTake(image);
    // navigate(location.state.from, { state: { image } });
    // navigate("/purpose");
  };

  const retakePhoto = () => {
    setImage(null);
  };
  // const handleError = (error) => {
  //   if (error.noCameraAccessible) {
  //     alert('Please allow camera access in your site settings. Go to chrome settings > Site settings > Camera > Allow');
  //   } else {
  //     console.error('Error accessing camera:', error);
  //   }
  // };

  return (
    // <Layout>
      <div className="wrapper">
        <div className="formContainer">
          <div className="cameraComponent">
           
            {image ? (
              <div className="cameraView">
                <img src={image} alt="Captured" className="imagePreview" />
                <div className="buttonsContainer">
                  <button onClick={retakePhoto} className="cameraButton">
                    Retake
                  </button>
                  <button onClick={savePhoto} className="cameraButton">
                    Save
                  </button>
                </div>
              </div>
            ) : (
              <div className="cameraClick">
                <Camera ref={camera} aspectRatio={1} className="cameraFeed" facingMode="user" />
                {

                }
                {/* <small>Go to chrome settings &gt; Site settings &gt; Camera &gt; Allow</small> */}
                <button onClick={capture} className="cameraButton">
                  Click here to Take Photo
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    // </Layout>
  );
};

export default CameraComponent;

import Dropdown from "../components/Dropdown/dropDown";
import Layout from "../components/Layout/layout";
import TextField from "../components/TextField/textfield";
import Button from "../components/Button";
import { useNavigate } from "react-router";
import { MdCall } from "react-icons/md";
import { CiMail } from "react-icons/ci";
import NavIndicator from "../components/NavIndicator";

const Contact = ({ formData, setFormData, data }) => {
  const navigate = useNavigate();
  // const countryCode = [
  //   { value: "+1", label: "+1" },
  //   { value: "+2", label: "+2" },
  //   { value: "+91", label: "+91" },
  // ];
  const countryCode = data?.country_codes;
  return (
    <div className="wrapper">
      <div className="formContainer">
        {/* <NavIndicator /> */}
        <h2 style={{paddingBottom:'5px'}}>Contacts</h2>
        <Dropdown
          icon={<MdCall className="call" size={20} />}
          onChangeTextInput={(e) =>
            setFormData({ ...formData, phoneNumber: e.target.value })
          }
          errorMessage={formData?.phoneNumberError}
          className={"countrycodeDropdown"}
          Options={countryCode}
          textInput={true}
          placeholder={"Enter number"}
          textInputValue={formData?.phoneNumber}
          value={formData.countryCode}
          onChange={(e) => {
            setFormData({
              ...formData,
              countryCode: e.value,
              phoneNumberError: "",
            });
          }}
        />
        <TextField
          icon={<CiMail className="mail" size={20} />}
          placeholder={"Enter your email address"}
          errorMessage={formData?.emailError}
          type={"text"}
          value={formData?.email}
          onChange={(e) => {
            setFormData({ ...formData, email: e.target.value, emailError: "" });
          }}
          e
        />
      </div>
    </div>
  );
};
export default Contact;

import React, { useState, useRef } from "react";
import Dropdown from "../components/Dropdown/dropDown";
import Layout from "../components/Layout/layout";
import Button from "../components/Button";
import { useNavigate } from "react-router";
import { Camera } from "react-camera-pro";
import { CiCamera } from "react-icons/ci";
import { IoMdCheckmark } from "react-icons/io";
import NavIndicator from "../components/NavIndicator";
import CameraComponent from "./camera";
import validation from "../services/Validation";

const Purpose = ({ formData, setFormData, setIsEnableButton, data }) => {
  const navigate = useNavigate();
  const camera = useRef(null);
  const [image, setImage] = useState(null);
  const [numberOfCameras, setNumberOfCameras] = useState(0);
  const [termsChecked, setTermsChecked] = useState(false);
  const [isActiveCamera, setIsActiveCamera] = useState(false);
  const [capturedImage, setCapturedImage] = useState(null);
  const [other, setOther] = useState();
  const Options = data?.purpose_of_visit;
  // const purpose = [...Options, ...[{ label: "Others", value:5 }]];

  const handleTermsChange = (e) => {
    setTermsChecked(e.target.checked);
  };

  const handleSubmit = () => {
    // if (termsChecked && image) {
    //   navigate("/purpose");
    // } else {
    //   alert("Please agree to the terms and upload a photo.");
    // }
  };

  return (
    // <Layout>
    <>
      {(!isActiveCamera && (
        <div className="wrapper">
          <div className="formContainer">
            {/* <NavIndicator /> */}
            <Dropdown
              label={"Purpose of Visit"}
              placeholder={"Purpose of Visit"}
              important={true}
              className={"purposeDropdown"}
              Options={Options}
              errorStyles={{ top: 40 }}
              value={formData?.purpose_of_visit}
              // inputValue={formData?.purpose_of_visit}

              // onInputChange={(e)=>{
              //   setFormData({
              //     ...formData,
              //     purpose_of_visit: e,
              //     puposeOfVisitError: "",
              //   });
              //   console.log("e", e)
              // }}

            
              onChange={(e)=>{

                setFormData({
                 ...formData,
                  purpose_of_visit: e.value,
                  puposeOfVisitError: "",
                });
              }}
              errorMessage={formData?.puposeOfVisitError}
            />
            {console.log("formData?.purpose_of_visit", formData?.purpose_of_visit)}
            {formData?.purpose_of_visit == 5 && (
              <>
              <textarea
                className="textarea"
                placeholder="Specify Others"
                value={formData.others_specify}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    others_specify:e.target.value ,
                    othersSpecifyError: "",
                  });
              
                }}
              >
              </textarea>
              <span className="errorTextOthers">{formData?.othersSpecifyError}</span>
             
              </>
            )}

            {(capturedImage && <img src={capturedImage} width={150} />) || (
              <div style={{ marginTop: 30 }}>
                <h4>
                  Upload a photo of yourself<span> *</span>
                </h4>
                <div className="photoContainer">
                  {image ? (
                    <img
                      src={image}
                      alt="Image preview"
                      className="imagePreview"
                      width={150}
                    />
                  ) : (
                    <button
                      onClick={() => {
                        setIsActiveCamera(true);
                        setIsEnableButton(false);
                      }}
                      className="camerabutton"
                    >
                      {/* <img src={cameraIcon} alt="Camera Icon" /> */}
                      <CiCamera className="camera" />
                      Take a photo of yourself using your device
                    </button>
                  )}
                  {formData?.photoError && (
                    <h6 className={"errorText"} style={{ position: "unset" }}>
                      {formData.photoError}
                    </h6>
                  )}
                </div>
              </div>
            )}

            <div className="checkboxWrapper">
              <input
                type="checkbox"
                id="terms"
                checked={formData?.termsChecked}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    termsChecked: e.target.checked,
                    termsCheckError: "",
                  });
                }}
              />
              <label htmlFor="terms">
                {/* {" "}
                I have read and agreed to the{" "}
                <a href="/terms-and-conditions" target="_blank">
                  terms and conditions
                </a>{" "}
                and{" "}
                <a href="/privacy-policies" target="_blank">
                  privacy policies
                </a> */}{" "}
                I am okay to share my details for the records of CGI
              </label>
              {formData?.termsCheckError && (
                <h6 className={"errorText"}>{formData.termsCheckError}</h6>
              )}
            </div>
          </div>
        </div>
      )) || (
        <CameraComponent
          onTake={(capturedImage) => {
            setIsActiveCamera(false);
            setCapturedImage(capturedImage);
            setFormData({ ...formData, photo: capturedImage });
            setIsEnableButton(true);
          }}
        />
      )}
    </>

    // </Layout>
  );
};

export default Purpose;

import React, { useState } from "react";
import "../index.css";
import Layout from "../components/Layout/layout";
import Button from "../components/Button";
import TextField from "../components/TextField/textfield";
import { useNavigate } from "react-router";
import validation from "../services/Validation";
import NavIndicator from "../components/NavIndicator";

const WelcomeScreen = ({ formData, setFormData }) => {
  const max_date = new Date().toISOString().split('T')[0];
  console.log(max_date);
  return (
    // <Layout>
    <div className="wrapper">
      <div className="formContainer">
        {/* <NavIndicator  /> */}
        <h2> Basic Information</h2>
        <TextField
          label={"First Name"}
          placeholder={"Enter"}
          important={true}
          type={"text"}
          errorMessage={formData?.firstNameError}
          value={formData.first_name}
          onChange={(e) => {
            setFormData({ ...formData, first_name: e.target.value,firstNameError:"" });
          }}
        />
        <TextField
          label={"Middle Name"}
          placeholder={"Enter"}
          value={formData.middle_name}
          errorMessage={formData?.middleNameError}
          type={"text"}
          onChange={(e) =>
            setFormData({ ...formData, middle_name: e.target.value ,middleNameError:""})
          }
        />
        <TextField
          label={"Surname/family name"}
          placeholder={"Enter"}
          type={"text"}
          value={formData.surname}
          onChange={(e) =>
            setFormData({ ...formData, surname: e.target.value,surnameError:"" })
          }
          errorMessage={formData?.surnameError}
        />
        <TextField
          label={"Date of Birth"}
          placeholder={"Enter"}
          type={"date"}
          value={formData.dob}
          onChange={(e) => setFormData({ ...formData, dob: e.target.value ,dobError:""})}
          errorMessage={formData?.dobError}
         important={true}
         max={max_date}
        />
      
      </div>
    </div>
    // </Layout>
  );
};

export default WelcomeScreen;

import React, { useState } from "react";
import styles from "./dropdown.module.css";
import { IoMdArrowDropdown } from "react-icons/io";
import TextField from "../TextField/textfield";
import Select from "react-select";


const Dropdown = ({
  Options = [],
  label,
  important,
  className,
  icon,
  textInput,
  placeholder,
  onChange,
  errorMessage,
  value,
  onChangeTextInput,
  textInputValue,
  errorStyles,
  data,
  inputValue,
  onInputChange
}) => {
  const selectedOption = Options.find((option) => option.value === value);

  return (
    <form className={styles.dropdown}>
      <label>
        {label}
        {important && <span> *</span>}
      </label>
      <div className={`${styles.selectContainer} ${className}`}>
        {icon &&<i>{icon}</i>}
        {/* < select onChange={onChange}>
          <option value={""}>Select an Option</option>
          {Options.map((item) => (
            <>
              <option selected={item?.value == value ? true :  ''} key={item?.value} value={item?.value} >
                {item?.label}
              </option>
            </>
          ))}
      </select> */}
      <div style={{width:250}}>
        <Select
          onChange={onChange}
          options={Options}
          // value={value}
          value={Options.filter((option) => option.value == value)}
          placeholder="Search"
          isSearchable
          className={styles.selectSearch}
          autosize={true}
          inputValue={inputValue}
          onInputChange={onInputChange}
          
        
        />
        </div>

        {/* <IoMdArrowDropdown className={styles.customArrow} /> */}
        {/* {textInput && <TextField className={styles.dropText} placeholder={placeholder}  type={"text"}/>} */}
        {textInput && (
          <input
            placeholder={placeholder}
            type="number"
            className={styles.dropText}
            value={textInputValue}
            onChange={onChangeTextInput}
          />
        )}
        {errorMessage && (
          <h6 style={errorStyles} className={styles.errorText}>
            {errorMessage}
          </h6>
        )}
      </div>
    </form>
  );
};
export default Dropdown;

import { useState } from "react";
import Button from "../components/Button";
import Layout from "../components/Layout/layout";
import { IoMdCheckmark } from "react-icons/io";
import TextField from "../components/TextField/textfield";
import StarRatings from "react-star-ratings";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate, useSearchParams } from 'react-router-dom';

import "react-toastify/dist/ReactToastify.css";

const Submit = () => {
  const [searchParams] = useSearchParams();

  const [rating, setRating] = useState(0);
  const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);
  const reference = searchParams.get('r');

  const navigate = useNavigate();

  const changeRating = (newRating) => {
    setRating(newRating);
  };
  const handleSubmitFeedback = () => {
    toast.success("Feedback submitted successfully!");
    setFeedbackSubmitted(true);
  };

  return (
    
      <div className="wrapper">
        <div className="submitContainer">
          <h1>Your form is submitted</h1>
          <p>
            Your registration reference no:<b>{reference}</b>
          </p>
          <Button buttonText={"Back to Home"} className={"Print"} onClick={()=>navigate("/")} />
          {/* <h2>Feedback on your experience</h2> */}
          {/* {!feedbackSubmitted && (
            <>
              <h4>
                Rate<span> *</span>
              </h4>
              <StarRatings
                rating={rating}
                starRatedColor="rgba(25, 92, 159, 1)"
                changeRating={changeRating}
                numberOfStars={5}
                starHoverColor="rgba(25, 92, 159, 1)"
                name="rating"
                starSpacing="2px"
                starDimension="25px"
              />
             
              <TextField
                textContent={true}
                label={"Feedback"}
                placeholder={"Tell us more about your experience"}
                className={"textarea"}
              />
              <Button
                icon={<IoMdCheckmark />}
                buttonText={"Submit Feedback"}
                className={"feedbackButton"}
                onClick={handleSubmitFeedback}
              />
            </>
          )} */}

        {/* {feedbackSubmitted && <h2>Feedback on your experience</h2> }   */}
        
          {/* <ToastContainer position="bottom-center" theme="colored"/> */}
        </div>
        
      </div>
   
  );
};
export default Submit;

import Router from "./Router/router";
import MultiStepForm from "./screens";


const App = () => {
  return (
    <>
      <Router />
     
    </>

  );
};
export default App;

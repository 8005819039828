import styles from "./button.module.css";

const Button = ({ buttonText, className, onClick , icon}) => {
  return (
    <button className={`${styles.button} ${className}`} onClick={onClick}>
      {icon && <i>{icon}</i>}{" "}
      {buttonText}
    </button>
  );
};
export default Button;

import styles from "./textfield.module.css";

const TextField = ({
  className,
  placeholder,
  label,
  style,
  textContent,
  important,
  upload,
  icon,
  errorMessage,
  onChange,
  value,
  type,
  required,
  max
  
}) => {
  return (
    <>
   
      {!textContent ? (
        <form className={`${styles.textbox} ${className}`}>
          <label for="fname">
            {label}
            {important && <span> *</span>}
          </label>

          <div className={styles.inputContainer}>
            {icon && <p>{icon}</p>}
            <input
              type={type}
              placeholder={placeholder}
              className={className}
              onChange={onChange}
              value={value}
              required={required}
              max={max}
            />
             {errorMessage && <h6 className={styles.errorText}>{errorMessage}</h6>}  
          </div>
        </form>
      ) : (
        <>
          <label>{label}</label>
          <textarea
            // className={className}
            placeholder={placeholder}
            onChange={onChange}
            value={textContent}
          ></textarea>
           {errorMessage && <h6 className={styles.errorText}>{errorMessage}</h6>}  
        </>
      )}
     
    </>
  );
};
export default TextField;

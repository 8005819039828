import { LuPcCase } from "react-icons/lu";

let validation = {
    notEmpty(value,attribue) {
        value = value;
        if (value == null || value.length < 1) {
            return {
                isValid: false,
                errorMessage:  `${attribue}  cannot be empty.`
              };
        }
        return {
            isValid: true,
            errorMessage: ""
            };
    },
    emailValidation(text) {
        
        if (text && !(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(text))) {
            return {
                isValid: false,
                errorMessage:  `Enter valid email address.`
              };
        }
        return {
            isValid: true,
            errorMessage:  ``
          };
    },
    phoneValidation(number) {
        const phoneRegex = /^[+]?[(]?\d{3}[)]?[-\s.]?\d{3}[-\s.]?\d{4}$/;
        if (number && !(phoneRegex.test(number))) {
            return {
                isValid: false,
                errorMessage:  `Enter valid phone number.`
              };
        }
        return {
            isValid: true,
            errorMessage:  ``
          };
    },

    validateText(name,attribue, isRequired = false){
        if (isRequired && (!name || name == null || name.length < 1)) {
            return {
              isValid: false,
              errorMessage: attribue+" cannot be empty."
            };
          }

        const nameRegex = /^[A-Za-z\s'-]+$/; // Allows letters, spaces, apostrophes, and hyphens
        if (name && !nameRegex.test(name)) {
            return {
              isValid: false,
              errorMessage:  `${attribue} can only contain letters, spaces, apostrophes, and hyphens.`
            };
          }

        return {
        isValid: true,
        errorMessage: ""
        };
    },
    validateIdNumber(idType,idNumber) {
      idType = parseInt(idType);
      if (idNumber == null || idNumber.length < 1) {
        return {
            isValid: false,
            errorMessage:  `ID Number  cannot be empty.`
          };
    }

      switch (idType) {
          case 1: // Passport No
          console.log((/^[A-Z0-9]{6,9}$/).test(idNumber));
              if (!(/^[A-Z0-9]{6,9}$/).test(idNumber)) {
                  return {
                    isValid: false,
                    errorMessage:  'Invalid Passport No',
                  };
              }
              return {
                isValid: true,
                errorMessage: '',
              };
              break;

          case 2: // OCI Card No
              if (!/^[A-Z0-9]{8,12}$/.test(idNumber)) {
                return {
                  isValid: false,
                  errorMessage:  'Invalid OCI Card No',
                };
              }
              return {
                isValid: true,
                errorMessage: '',
              };
              break;

          case 3: // Driving License No
              if (!/^[A-Z0-9]{6,15}$/.test(idNumber)) {
                return {
                  isValid: false,
                  errorMessage:  'Invalid Driving License No',
                };
              }
              return {
                isValid: true,
                errorMessage: '',
              };
              break;

          case 4: // Australian Citizenship No
              if (!/^[A-Z0-9]{8,12}$/.test(idNumber)) {
                return {
                  isValid: false,
                  errorMessage:  'Invalid Australian Citizenship No',
                };
              }
              return {
                isValid: true,
                errorMessage: '',
              };
              break;

          case 5: // Photo Card No
          if (/^[0-9]{10}$/.test(idNumber)){
            return {
              isValid: false,
              errorMessage:  'Invalid Photo Card No',
            };
          }    

          default:
            return {
              isValid: true,
              errorMessage: '',
            };
              break;
      }
  },

     getContentTypeFromBase64(base64String) {
      // Extract the content type using a regular expression
      const matches = base64String.match(/^data:(image\/\w+);base64,/);
      return matches ? matches[1] : null;
  },
   base64ImageToBlob(base64Image) {
    let contentType = this.getContentTypeFromBase64(base64Image);
    // Remove the data URL scheme part if it exists
    const base64Data = base64Image.replace(/^data:image\/\w+;base64,/, '');

    const byteCharacters = atob(base64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
        const slice = byteCharacters.slice(offset, offset + 512);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
},
validateDate(date, attribute) {
  if (!date) {
      return {
          isValid: false,
          errorMessage: `${attribute} cannot be empty.`
      };
  }
  return {
      isValid: true,
      errorMessage: ""
  };
},

  
}

export default validation;